import data from "../data/data.js"   
export default function Services() {
    return ( 
        <div className="lg:grid grid-cols-3 gap-10 p-10">     
            {data.map((service, i) => (     
              <div className="text-center shadow-md shadow-pink p-10 rounded-xl my-6 font-quicksand text-dk-blue bg-white">
                <div className="flex justify-center"><img key={i} className="lg:rounded-md" width="350px" height="150px" src={service.img} alt={service.title} /></div>
                <div><h2 className="text-2xl lg:mb-4">{service.title}</h2></div>
                <div><p className="text-sm text-justify">{service.description}</p></div>
                <div className="p-4"><a href={service.link} className='px-7 py-3 text-sm  text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>{service.button}</a></div> 
              </div>         
            ))}        
        </div>   
    )
}

    