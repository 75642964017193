import AboutCoaches from "../Components/Coaching/AboutCoaches";
import OurJourney from "../Components/Coaching/OurJourney";
import CoachingPackages from "../Components/Coaching/CoachingPackages";
import Certifications from "../Components/Certifications";

export default function AboutUs() {
    return (
       <div className="">
            <div className="">
                <OurJourney />
                <AboutCoaches />
                <div className="bg-dk-blue"> <CoachingPackages /></div>
                <Certifications />
            </div>
        </div> 
    )
}