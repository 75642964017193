import React from 'react'
import DecNewsletter from '../../PDF/NewsletterJan25.pdf'
import DreamDayImg from '../../images/DreamDay25Flyer.png'
import Register from './Register'

export default function DreamDay() {
  return (
    <div>
      <div className='flex justify-center pt-10'>
        <div className='m-10'>
          <a href={DreamDayImg} target='_blank' rel="noreferrer"><img src={DreamDayImg} alt="Dream Day 25" width={500}/></a>
          <a href={DecNewsletter} target='_blank' rel='noreferrer'><span className='flex justify-center font-quicksand pt-5'>Click here to learn more.</span></a>
          <button  className='mt-4 flex justify-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'><a href='https://www.paypal.com/ncp/payment/3YB63M7RFVBAU'target='_BLANK' rel='noreferrer'>PAY NOW</a></button>
          </div>
          
        
            <div>
       <Register />
        </div>
        </div>
    </div>
  )
}
