import { useState, useRef } from "react"
import emailjs from '@emailjs/browser';
import { Category } from "@mui/icons-material";

export default function SignUp() {
    const form = useRef();
    const [selectedValue, setSelectedValue] = useState("");
 



  // Handle the change event of the dropdown
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
   
  };
    
    const sendEmail = (e) => {
        alert("Thank you for registering.")
        e.preventDefault();
  
      emailjs.sendForm('service_tv43tvm', 'template_uau8d4p', form.current, 'J_RZqGwfU2pfxmsaE') 
       .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        })
        setSelectedValue("");
        
    };
    return (
           
            <section className="h-screen mb-0 py-6">
            <div className="grid relative font-quicksand max-w-6xl grid-cols-1 p-32 rounded-lg bg-[hsla(0,0%,100%,0.75)] px-6 mx-auto lg:px-8 md:grid-cols-2">
            <div className="py-6 md:py-0 md:px-6">
                <h2 className="text-2xl lg:text-3xl lg:mb-4 lg:font-bold">Register here.</h2>
                <p className="text-sm text-justify lg:text-md lg:pb-3 lg:text-justify">Thank you for your interest in Coaching, Training or our Upcoming Event.  Please complete the registration form and I will be in contact with you within 24-48 hours to complete this registration.  </p>
            </div>
            <div>
                <form ref={form} onSubmit={sendEmail} className="space-y-6">
                    <div>
                        <input name='user_name' id="name" type="text" placeholder="Name" className="w-full p-3 rounded uppercase dark:bg-gray-800"  required  />
                    </div>
                    <div>
                        <input name='user_email' id="email" type="email" placeholder="Email" className="w-full uppercase p-3 rounded dark:bg-gray-800"   required />
                    </div>
                    <div>
                        <input name='user_phone' id="tele" type="text" placeholder="Phone Number" className="w-full uppercase p-3 rounded dark:bg-gray-800"   required />
                    </div>
                    <div>
                    <select input name="category" className="w-full uppercase p-3 rounded dark:bg-gray-800" required id="dropdown" value={selectedValue} onChange={handleChange}>
                        <option value="" disabled>Select an option</option>
                        <option value="Individual Coaching">Individual Coaching</option>
                        <option value="MMG Training">MMG Training</option>
                        <option value="Dream Day Registration">Dream Day Registration</option>
                        <option value="Other">Other, please specify.</option>
                    </select> 
                    </div>
                    <div>
                        <textarea name='message' id="message" placeholder="Message" rows="3" className="w-full p-3 uppercase rounded dark:bg-gray-800"></textarea>
                    </div>
                    <div  className="mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink">
                        <button 
                            type="submit"> 
                                Register
                        </button>
                    </div>
                </form>
            </div>
            </div>
            </section>
    ); 
}
