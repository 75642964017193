import React, { useState } from 'react'

export default function SubscriptionForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        download: ''
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send the formData to your backend for processing
        console.log(formData);
      };
    
      return (
        <div className='w-96 m-auto p-5 '>
            <h2>Scribe for Other Freebies:</h2>
            <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input 
                    type="text" 
                    name="name" 
                    value={formData.name} 
                    onChange={handleChange} 
                />
            </label>
            <label>
                Email:
                <input 
                    type="email" 
                    name="email" 
                    value={formData.email} 
                    onChange={handleChange
                    
                } />
            </label>
            <label>
                Phone:
                <input 
                    type="tel" 
                    name="phone" 
                    value={formData.phone} 
                    onChange={handleChange} 
                />
            </label>
            <label>
                Download:
                <select name="download" value={formData.download} onChange={handleChange}>
                <option value="">Select Download</option>
                <option value="download1">Year In Review</option>
                <option value="download2">Event 2</option>
                </select>
            </label>
            <button 
                type="submit">
                    Register
            </button>
        </form> 
        </div>
      );
    
    }
    
  