import Services from "../Components/Services";
import ServicesTitle from "../Components/ServicesTitle";

export default function ServicesListing() {
    return (
        <div>
            <div className="m-5 md:container md:mx-auto bg-white">
            <h1 className="text-4xl font-greatvibes text-dk-blue text-center m-5 p-3 md:text-6xl">How Can I Serve You? </h1>
            <p className="text-justify pb-5 font-quicksand md:text-center text-dk-blue lg:text-lg">Through the following resources and services, I help to <span className="font-yeseva text-xl text-pink font-extrabold md:text-3xl ">empower</span> women to create <span className="font-yeseva text-xl text-pink font-extrabold md:text-3xl ">authentic</span> and <span className="font-yeseva text-xl text-pink font-extrabold md:text-3xl ">impactful</span> personal brands that reflect their true selves and achieve their aspirations.</p>
           
        </div>
             <div className="bg-dk-blue"><Services /></div>
        </div>
    );
}