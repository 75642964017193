import React from 'react'
import { Link } from 'react-router-dom'

export default function WhyLABL() {
  return (
    <div className='md:container mx-auto text-dk-blue'>
      
        <h1 className="font-quicksand md:text-5xl">Why Choose <span className='font-yeseva'>LIVE A BETTER LIFE 24•7?</span></h1>
        <ul className="m-10 list-disc font-quicksand md:text-xl">
            <li><span className='md:text-3xl font-yeseva text-pink underline font-extrabold'>Expertise and Experience</span>: With years of experience in life coaching and personal branding, we understand the unique challenges women face during life transitions.</li>
            <li><span className='md:text-3xl font-yeseva text-pink underline font-extrabold'>Empathetic and Supportive</span>: Our approach is empathetic and supportive, providing a safe space for you to explore, grow, and thrive.</li>
            <li><span className='md:text-3xl font-yeseva text-pink underline font-extrabold'>Results-Driven</span>: We focus on achieving real, tangible results that help you reach your personal and professional goals.</li>
        </ul>
      
        <div>
        <h1 className="font-quicksand md:text-2xl lg:text-3xl">Ready to embrace the next chapter of your life with confidence and clarity?</h1>
        <p className="font-quicksand md:text-lg lg:text-xl">Let's work together to define your brand, navigate your transition, and empower you to live a life that's true to who you are. Contact us today to schedule your initial consultation and start your journey toward a more authentic and empowered you.</p>
        <div>
            <button className='animate-pulse font-quicksand mt-10 mb-10 px-7 py-3 text-md font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'> <Link to="/services">More Info</Link></button>
        </div>
        </div>
    </div>
  )
}
