import React from 'react'
import SubscriptionForm from './SubscriptionForm'

export default function Freebies() {
  return (
    <div>
        <h1>Freebies</h1>
        <p>Why isn't this working?</p>
        <div>
            <SubscriptionForm />
        </div>
        
    </div>
  )
}
