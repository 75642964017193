import React from 'react'
import { Link } from 'react-router-dom'
import DiscImg from '../images/ML DISC Zoom Graphic Background 2.png'
import DiscImg2 from '../images/DISC-Diagram.png'

export default function DiscMethod() {
  return (
    <div className='p-2 text-dk-blue'>
        <img src={DiscImg} alt='DISC Method'/>
        <h1 className="font-quicksand text-3xl text-center mb-5 md:text-5xl">ML DISC Assessments</h1>
        <div className='md:container md:mx-auto font-quicksand md:text-xl text-dk-blue'>
                  <p>The best way to <span className="font-yeseva text-3xl font-extrabold">grow</span> yourself is to <span className="font-yeseva text-3xl font-extrabold">know</span> yourself.  Understanding your unique strengths and potential challenges of your DISC style will help you be intentional in all areas of your life (work, relationships, and all areas of communication).</p>
                  <br></br>
                 {/* style={{ backgroundImage: `url(${DiscImg2})` }} */}
                <h1 className='text-3xl md:text-5xl'>ML DISC Packages:</h1>
                <div className='border-4 rounded-lg border-dk-blue " bg-cover' >
                <ul className="text-2xl md:m-10 md:text-5xl">
                        <li>ML DISC Personality Indicator Report - $50</li>
                            <p className='pl-3 text-lg'>Includes: Report online.</p>
                        <li>ML DISC Personality Indicator Report & Debrief - $197</li>
                            <p className='pl-3 text-lg underline'>Includes: </p>
                                <ul className='text-sm md:ml-10 md:text-lg list-disc'>
                                    <li>Maxwell DISC Personality Indicator Report</li>
                                    <li>Debrief Session - 60 min.</li>
                                </ul>
                        <li>ML DISC Method Package 1 - $597</li>
                        <p className='pl-3 md:text-lg underline'>Includes: </p>
                        <ul className='text-sm md:ml-10 md:text-lg list-disc'>
                                    <li>Maxwell DISC Personality Indicator Report</li>
                                    <li>Debrief Session - 60 min.</li>
                                    <li>3 Coaching Sessions - 60 min.</li>
                                </ul>
                        <li>ML DISC Method Package 2 - $997</li>
                        <p className='pl-3 md:text-lg underline'>Includes: </p>
                        <ul className='text-sm md:ml-10 md:text-lg list-disc'>
                                    <li>Maxwell DISC Personality Indicator Report</li>
                                    <li>Debrief Session - 60 min.</li>
                                    <li>6 Coaching sessions - 60 min.</li>
                                </ul>
                        <li>ML DISC Method Team Training - $247</li>
                        <p className='pl-3 md:text-lg underline'>Includes: </p>
                        <ul className='text-sm md:ml-10 md:text-lg list-disc'>
                                    <li>Maxwell DISC Personality Indicator Report</li>
                                    <li>Maxwell DISC Training program OR Mastermind Group (MMG)</li>
                                </ul>
                        
                </ul>
                </div>
                <p className="">I will,  at your request, incorporate your organization’s meeting theme, mission/vision statement or build on your existing training schedule to best fit the needs of your group.</p>
                <br/>
                <Link to="https://www.paypal.com/ncp/payment/9MJ6SF5GWVYXN"> <button className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>Buy Now</button></Link>
                </div>
      </div>
  )
}
