export default function MainTraining(){
    return (
        <div className="p-2 font-quicksand text-lg md:m-5 md:container">
                <h1 className="font-greatvibes text-5xl text-dk-blue text-center mb-5">Upcoming Training (Mastermind Group)</h1>
                 <div className="flex-1">
                    <div className="flex justify-center"><img src={require("../../images/The15InvaluableLawsOfGrowth.jpg")} className="rounded-md object-fill" alt="Coaches" width="500px" height="500px" /></div>
                    <h2 className="font-yeseva md:text-3xl text-dk-blue mb-5 font-extrabold pt-4">15 Invaluable Laws of Growth • 5 Weeks MMG • Starting Tuesday, February 11, 2025 • $25pp</h2>
                    <p className="md:text-xl"> John C. Maxwell says in order to reach your potential, you must be intentional about personal growth. This training will help you understand how personal growth really works, and how you can develop yourself to become a more effective and fulfilled individual. You will learn how to build up your sense of purpose and become more successful in every area of your life.  Sign up for this 5-week growth journey to become the person you are destined to be!</p>
                    <br></br>
                    <p className="md:text-xl">These session are virtual. These sessions can be faciliated individually through Life Coaching or as a group through a training.</p>
                    <br></br>
                    <a href="/Register" className='mt-4 inline-flex items-center px-7 py-3 text-sm font-medium text-center text-white bg-dk-blue rounded-3xl hover:bg-pink hover:text-dk-blue focus:ring-4 focus:outline-none focus:ring-dk-blue dark:bg-pink dark:hover:bg-dk-blue dark:focus:ring-pink'>Register Here</a>
                </div>
         </div>
       
    );
}