import React from 'react'

export default function LABLMission() {
  return (
    <div className="md:container md:mx-auto">
        <div className="text-lg p-2 text-dk-blue font-quicksand md:text-xl md:p-5">
            <div>
                <p><span className="font-yeseva md:text-2xl">LIVE A BETTER LIFE 24•7</span> is dedicated to helping women create personal brands that empower them to achieve their professional and personal goals. By providing resources and guidance, we support women in developing brands that are not only professional and polished but also deeply authentic and reflective of their true selves. This approach enables women to stand out and connect meaningfully in their personal and professional environments.</p>
            </div>
        </div>
    </div>
  )
}
