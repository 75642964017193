
const services = [
    {
        img: require("../images/life-coach.png"),
        title: "Personal Growth Coaching",
        description: "Establish your personal growth plan and achieve the change you desire through a partnership with a Certified Life Coach.",
        button: "Click Here",
        link: "Coaching",
    },
    {
        img: require("../images/web_serv.png"),
        title: "Branding Services",
        description:"Build your brand with a comprehensive package that includes a website, logo, business cards, postcards, flyers, signage, as well as social media and email marketing.",
        button: "Click Here",
        link: "#",
    },
    // {
    //     img: "../images/event-plan.png",
    //     title: "Project Mgmt/Event Planning",
    //     description: "As an project manager or event planner, I will coordinate all aspects of your project/event including preparing timelines and to-do lists, setting up vendor arrangements, ensuring that all aspects of your project come together from beginning to end within your desired budget. ",
    //     button: "More Info Coming Soon",
    //     link: "#",
    // },

    {
        img: require("../images/DISC-Diagram.png"),
        title: "DISC Assessments ",
        description: "The best way to grow yourself and others is to understand your personality and what naturally drives you. By recognizing your strengths and weaknesses, you can give your best as you collaborate with those around you.  Schedule an assessment test today.",
        button: "Schedule",
        link: "DiscMethod",
    },
    {
        img: require("../images/The15InvaluableLawsOfGrowth.jpg"),
        title: "Speaking & Workshops",
        description: "If you are looking for a motivational speaker for an upcoming event to ignite a spark within individuals, encouraging them to push past their limits, embrace challenges, and achieve their fullest potential, let's chat.",
        button: "Booking",
        link: "Speaking",
    },
    {
        img: require("../images/PutYourDreamToTheTestMastermind_Ad2.png"),
        title: "Group Training",
        description: "I believe that every individual has the capacity to achieve greatness, and my goal is to unlock that potential through powerful and impactful presentations. My approach is not just to motivate, but to equip attendees with the tools and strategies they need to implement lasting change in their personal and professional lives",
        button: "Click Here",
        link: "Trainings",
    },
    {
        img: require("../images/PutYourDreamToTheTestMastermind_Ad2.png"),
        title: "Upcoming Events",
        description: "I believe that every individual has the capacity to achieve greatness, and my goal is to unlock that potential through powerful and impactful presentations. My approach is not just to motivate, but to equip attendees with the tools and strategies they need to implement lasting change in their personal and professional lives",
        button: "Click Here",
        link: "/dreamday",
    },
    // {
    //     img: require("../images/Wellness.png"),
    //     title: "Health & Wellness",
    //     description: "As a Health and Wellness Distributor, we provide products that promote health and wellbeing, such as energy, weight loss or weight management solutions. We believe we have the products to help you on your health and wellness journey.  ",
    //     button: "Shop Here",
    //     link: "https://partner.co/s/OWJkZjRhYzdl",
    // },
];
export default services;